import { Avatar, Flex, Image } from '@chakra-ui/react'
import React from 'react'

export const ConsumerNavbar: React.FC<{ name?: string }> = ({
  name,
}) => {
  return (
    <Flex width='100%' justifyContent={'space-between'}>
      <Flex alignItems={'center'}>
        <Image
          height='30px'
          src='/icons/SAC_PayLeaf_Logo_app.svg'
          color='#333'
        ></Image>
      </Flex>

      <Flex justifyContent={'center'}>
        <Avatar
          size='sm'
          w='38px'
          h='38px'
          name={name}
          backgroundColor='#65B28A'
          color='white'
        ></Avatar>
      </Flex>
    </Flex>
  )
}
