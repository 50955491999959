import { Alert, AlertIcon, Box, Flex, Spinner } from '@chakra-ui/react'
import { loadStripeOnramp } from '@stripe/crypto'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useAsyncFn } from 'react-use'
import { trpc } from '../../utils/trpc'
import { useNativeOrOAuthWallet } from '../common/auth/authHooks'
import { usePayleafUser } from '../user/consumerHooks'
import { CryptoElements, OnrampElement } from './StripeOnRampElements'

// Make sure to call loadStripeOnramp outside of a component’s render to avoid
// recreating the StripeOnramp object on every render.
// This is your test publishable API key.
const stripeOnrampPromise = loadStripeOnramp(
  process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY as string
)

export const StripeOnRamp = () => {
  const { publicKey } = useNativeOrOAuthWallet()
  const [clientSecret, setClientSecret] = useState('')
  const [stripeError, setStripeError] = useState('')
  const [message, setMessage] = useState('')

  const createStripeOnrampSession = trpc.useMutation(
    'onramp.createStripeOnrampSession',
    {
      onSuccess(data) {
        console.log('created session', data)
        setClientSecret(data.clientSecret)
      },
      onError(err) {
        console.log('error for stripe onramp', err)
      },
    }
  )

  useEffect(() => {
    async function createSession() {

      const result = await axios.get('https://ipinfo.io/json?token=2cfc2ac144c274')
      console.log('ip address result', result.data)

      if (publicKey) {
        try {
          const session = await createStripeOnrampSession.mutateAsync({
            wallet: publicKey.toBase58(),
            ipAddress: result.data.ip,
            city: result.data.city,
            country: result.data.country,
          })
        } catch (err) {
          console.log('error for stripe onramp', err)
        }
      }
    }

    createSession()
  }, [])

  const onChange = useCallback(
    ({ session }: { session: { status: string } }) => {
      console.log('onchange session', session)

      setMessage(`OnrampSession is now in ${session.status} state.`)
    },
    []
  )

  const onReady = useCallback((payload: any) => {
    console.log('onready payload', payload)
  }, [])

  return (
    <Box>
      <CryptoElements stripeOnramp={stripeOnrampPromise}>
        {createStripeOnrampSession.isLoading && (
          <Flex w='100%' h='100%' justifyContent={'center'} alignItems='center'>
            <Spinner></Spinner>
          </Flex>
        )}
        {!!createStripeOnrampSession.error?.message && (
          <Box>
            <Alert borderRadius='12px' status='error'>
              <AlertIcon />
              {createStripeOnrampSession.error?.message}
            </Alert>
          </Box>
        )}
        {clientSecret && (
          <OnrampElement
            clientSecret={clientSecret}
            appearance={{ theme: 'light' }}
            onChange={onChange}
            onReady={onReady}
          />
        )}
      </CryptoElements>
      {message && <Box id='onramp-message'>{message}</Box>}
    </Box>
  )
}
