import React from 'react'
import {Text, TextProps} from '@chakra-ui/react'

export const SubTitle: React.FC<TextProps> = ({
  children,
  ...props
}) => {
  return (
    <Text color='#333' fontSize='1.1rem' fontWeight={500} {...props}>
      {children}
    </Text>
  )
}
