import { Flex, Text } from '@chakra-ui/react'

export const InfoBox = ({
  children,
  icon,
}: {
  children: React.ReactNode
  icon: any
}) => {
  return (
    <Flex marginY='.4rem' padding='.6rem' borderRadius={'12px'} bg='#f6f8f8' flexDirection='row' justifyItems='center' alignItems='center'>
      <Text fontSize={['1.25rem', '2rem']}>{icon}</Text>
      <Flex ml={2} flexDirection='column'>{children}</Flex>
    </Flex>
  )
}

export const InlineInfoBox = ({
  children,
  icon,
}: {
  children: React.ReactNode
  icon: any
}) => {
  return (
    <Flex borderRadius={'12px'} background={'#f6f8f8'} marginTop='1rem' padding='.4rem' flexDirection='row' justifyItems='center' alignItems='center'>
      <Text fontSize={['1rem', '1.3rem']}>{icon}</Text>
      <Flex ml={2} flexDirection='column'>{children}</Flex>
    </Flex>
  )
}
