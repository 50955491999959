import { IconButton, IconButtonProps } from '@chakra-ui/react'
import React from 'react'
import { RiArrowLeftSLine } from 'react-icons/ri'

export const BackIcon: React.FC<IconButtonProps & { onBack: () => void }> = ({ onBack }) => {
  return (
    <IconButton
      aria-label='back-icon'
      icon={<RiArrowLeftSLine />}
      style={{
        backgroundColor: 'white',
        border: '1px solid #e8e8e8',
        color: '#333',
        fontWeight: 600,
        fontSize: '20px',
        borderRadius: '15px',
        width: '36px',
        height: '36px',
      }}
      onClick={onBack}
    />
  )
}
