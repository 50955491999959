import { ArrowLeftIcon, CopyIcon } from '@chakra-ui/icons'
import { Alert, Box, Flex, Icon, IconButton, Text } from '@chakra-ui/react'
import { FC, useCallback } from 'react'
import { Player, Controls } from '@lottiefiles/react-lottie-player'
import QRScanAnimation from '../../assets/animations/confetti.json'
import { PhantomIcon } from '../../components/Icons/PhantomIcon'
import QRCode from 'react-qr-code'
import {
  RiArrowDropLeftLine,
  RiArrowLeftLine,
  RiArrowLeftSLine,
  RiInformationLine,
} from 'react-icons/ri'
import { BackIcon } from './BackIcon'
import { toast } from 'react-hot-toast'
import { InfoBox, InlineInfoBox } from '../common/components/infoBox'

interface Props {
  pubKey: string
  onBack: () => void
}

export const QRPubkeyScan: FC<Props> = ({ pubKey, onBack }) => {
  const copyAddress = useCallback(async () => {
    if (pubKey) {
      await navigator.clipboard.writeText(pubKey)
      toast.success('Copied', { id: 'copied' })
    }
  }, [pubKey])

  return (
    <Box width={'100%'}>
      <BackIcon aria-label='back-icon' onBack={onBack}></BackIcon>

      <Flex flexDirection={'column'} alignItems='center' marginTop={'-40px'}>
        <Player
          autoplay
          loop
          src={QRScanAnimation}
          style={{ width: '120px', height: '110px' }}
        ></Player>
        <Text
          w={'100%'}
          fontSize={['.875rem']}
          fontWeight={400}
          lineHeight='134%'
          color='#333'
          mt='1rem'
          textAlign={'center'}
        >
          You can deposit funds to your account via the Solana blockchain.
          Either scan the QRCode or copy the public key below and send USDC to
          it.
        </Text>
        <Box mt='1rem'>
          <Flex padding='.5rem' w='100%' justifyContent={'center'}>
            <QRCode fgColor='#333' value={pubKey} size={160} />
          </Flex>
          <Flex mt='1rem' alignItems={'center'}>
            <Text align='center' fontSize={'1rem'}>
              {pubKey.substring(0, 6)}.....{pubKey.substring(pubKey.length - 6)}
            </Text>
            <IconButton ml={1} border='none' borderRadius={'full'} aria-label='copyPubKey'>
              <CopyIcon
                onClick={copyAddress}
                _hover={{ cursor: 'pointer' }}
              ></CopyIcon>
            </IconButton>
          </Flex>
        </Box>

        <InlineInfoBox icon='⚠️'>
          <Text>Make sure to deposit USDC only</Text>
        </InlineInfoBox>
      </Flex>
    </Box>
  )
}
