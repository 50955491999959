import { EPaymentStatus } from '@prisma/client'
import axios from 'axios'
import { useQuery } from 'react-query'
import { trpc } from '../../utils/trpc'
import { useUser } from '../common/auth/authHooks'

export function usePayleafUser() {
  const {isAuthed} = useUser()
  const payLeafUserRes = trpc.useQuery(['user.getPayLeafConsumer'], {enabled: !!isAuthed})

  return {payLeafUser: payLeafUserRes.data, ...payLeafUserRes}
}

export function usePaymentTx(
  paymentId?: string | string[] | undefined,
  wallet?: string,
  paymentStatus?: EPaymentStatus
) {
  
  return useQuery(
    ['getPayment', paymentId, wallet],
    async () => {
      if (!paymentId || !wallet) {
        console.log('no paymentid or wallet', paymentId, wallet);
        return
      } 

      const protocol = window.location.protocol
      console.log({protocol});
      
      const hostname = window.location.hostname + (window.location.hostname === 'localhost' ? ':3000' : '')

      const paymentTx = await axios.post(
        `${protocol ?? 'https:'}//${hostname ?? 'payleaf.io'}/api/pay/${paymentId}`,
        { account: wallet }
      )
      console.log('return from payment tx', paymentTx.status);
      
      return paymentTx.data
    },
    { enabled: !!paymentId && !!wallet && paymentStatus !== 'SUCCESS' && paymentStatus !== 'CONFIRMED', refetchInterval: 20000 }
  )
}