export const consumerPrimaryButtonStyle: React.CSSProperties = {
  background:
    '#2AB365',
  border: 'none',
  borderRadius: 'full',
  color: 'white',
  fontSize: '.9rem',
  fontWeight: 700,
  minWidth: '14rem',
  height: '3.2rem',
}

export const consumerPrimaryButtonStyleFull: React.CSSProperties = {
  ...consumerPrimaryButtonStyle,
  height: '3.2rem',
  minWidth: '16rem',
  width: '100%'
}

export const consumerSecondaryButtonStyle: React.CSSProperties = {
  background:
    '#333',
  borderRadius: '42px',
  border: 'none',
  color: 'white',
  fontSize: '.9rem',
  fontWeight: 800,
  minWidth: '9rem',
  height: '2.4rem',
}

export const consumerPrimaryButtonStyleBasics: React.CSSProperties = {
  background:
    'linear-gradient(93.36deg, #3D756F 0%, #157840 59.38%, #06792D 100%)',
  color: '#fff',
}

export const consumerOutlinedButtonStyle: React.CSSProperties = {
  background: '#fff',
  border: '1px solid #3D756F',
  color: '#3D756F',
  fontSize: '18px',
  fontWeight: 500,
  height: '54px',
}
