import { Box, Flex, Image, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { BsBank, BsFillCreditCard2BackFill } from 'react-icons/bs'
import { useNativeOrOAuthWallet } from '../../../common/auth/authHooks'
import { StripeOnRamp } from '../../../onramp/StripeOnRamp'
import { BackIcon } from '../../../puffPay/BackIcon'
import { QRPubkeyScan } from '../../../puffPay/QRPubkeyScan'
import { CleanOutlineButton } from '../../buttons/CleanOutlineButton'
import { SubTitle } from '../../labels/Subtitle'
import { Title } from '../../labels/Title'

enum ETopUpAccountOptions {
  HOME,
  QR_CODE,
  STRIPE,
}

export const TopUpAccount = ({ onBack }: { onBack: () => void }) => {
  const [optionToShow, setOptionToShow] = useState<ETopUpAccountOptions>(
    ETopUpAccountOptions.HOME
  )
  const { publicKey } = useNativeOrOAuthWallet()

  return (
    <>
      {optionToShow === ETopUpAccountOptions.HOME && (
        <Stack mt='2rem' w='100%'>
          <BackIcon aria-label='back-icon' onBack={onBack}></BackIcon>
          <Title textDecoration='underline' textDecorationColor={'#65B28A'}>
            Top up account
          </Title>
          <SubTitle paddingBottom='1.5rem' mt='1rem'>
            Select source to top up your account. This will send funds to your
            wallet.
          </SubTitle>

          <SimpleGrid columns={[2]}>
            <Box
              _hover={{ cursor: 'pointer' }}
              onClick={() => setOptionToShow(ETopUpAccountOptions.STRIPE)}
              margin={2}
              bg='#111'
              paddingY={3}
              paddingX={4}
              borderRadius='18px'
            >
              <BsFillCreditCard2BackFill fill='#fff' fontSize='1.8rem'></BsFillCreditCard2BackFill>
              <Text mt={2} color='#fff' fontWeight={600}>
                Card
              </Text>
              <Text mt={0} fontSize='.7rem' color='#e7e7e7'>
                by Stripe (US only)
              </Text>
            </Box>
            <Box
              _hover={{ cursor: 'pointer' }}
              onClick={() => setOptionToShow(ETopUpAccountOptions.STRIPE)}
              margin={2}
              bg='#147D5A'
              paddingY={3}
              paddingX={4}
              borderRadius='18px'
            >
              <BsBank fill='#ebebeb' fontSize='1.8rem'></BsBank>
              <Text mt={2} color='#ebebeb' fontWeight={600}>
                Bank
              </Text>
              <Text mt={0} fontSize='.7rem' color='#ebebeb'>
                by Stripe (US only)
              </Text>
            </Box>
            <Box
              _hover={{ cursor: 'pointer' }}
              onClick={() => setOptionToShow(ETopUpAccountOptions.QR_CODE)}
              margin={2}
              bg='#F2F8FA'
              paddingY={3}
              paddingX={4}
              borderRadius='18px'
            >
              <Image src='/icons/sol-logo-black.svg' height='1.6rem' width='auto'></Image>
              <Text mt={2} color='#444' fontWeight={600}>
                USDC (SOL)
              </Text>
              <Text mt={0} fontSize='.7rem' color='#6a6a6a'>
                on Solana via Public Key
              </Text>
            </Box>
          </SimpleGrid>
        </Stack>
      )}

      {optionToShow === ETopUpAccountOptions.QR_CODE && publicKey && (
        <Flex width='100%' justifyContent={'center'} mt='2rem'>
          <QRPubkeyScan
            pubKey={publicKey.toBase58()}
            onBack={() => setOptionToShow(ETopUpAccountOptions.HOME)}
          ></QRPubkeyScan>
        </Flex>
      )}

      {optionToShow === ETopUpAccountOptions.STRIPE && publicKey && (
        <Stack mt='2rem'>
          <BackIcon
            aria-label='back-icon'
            onBack={() => setOptionToShow(ETopUpAccountOptions.HOME)}
          ></BackIcon>
          <StripeOnRamp></StripeOnRamp>
        </Stack>
      )}
    </>
  )
}
