import React from 'react'
import {Text, TextProps} from '@chakra-ui/react'

export const Title: React.FC<TextProps> = ({
  children,
  ...props
}) => {
  return (
    <Text mt='1rem' fontSize='1.3rem' fontWeight={700} {...props}>
      {children}
    </Text>
  )
}
